import { useEffect } from "react";

export default function Grab() {
  useEffect(() => {
    if (window) {
      window.location.href =
        "https://food.grab.com/my/en/restaurant/yum-coffee-kepong-delivery/1-C4KYLBMJT4EKTN";
    }
  }, []);

  return null;
}
