import { FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";
import { SiXiaohongshu } from "react-icons/si";

import ContactCard from "./components/ContactCard";

type Contact = {
  id: number;
  label: string;
  link: string;
  icon: React.ReactNode;
  bgColor: string;
};

const contacts: Contact[] = [
  {
    id: 1,
    label: "Whatsapp",
    link: "https://wa.me/60122482366",
    icon: <FaWhatsapp className="text-2xl" />,
    bgColor: "bg-[#25D366]", // WhatsApp's green
  },
  {
    id: 2,
    label: "Instagram",
    link: "https://www.instagram.com/yumcoffeenow",
    icon: <FaInstagram className="text-2xl" />,
    bgColor: "bg-[#E4405F]", // Instagram's pink
  },
  {
    id: 3,
    label: "Facebook",
    link: "https://www.facebook.com/share/15BP57Figy",
    icon: <FaFacebook className="text-2xl" />,
    bgColor: "bg-[#1877F2]", // Facebook's blue
  },
  {
    id: 4,
    label: "小红书 (XHS)",
    link: "https://www.xiaohongshu.com/user/profile/62753cef000000002102be7b",
    icon: <SiXiaohongshu className="text-2xl" />,
    bgColor: "bg-[#FE2C55]", // Xiaohongshu's red
  },
];

export default function Home() {
  function renderContacts() {
    return contacts.map((contact) => {
      return (
        <ContactCard
          key={contact.id}
          label={contact.label}
          link={contact.link}
          icon={contact.icon}
          bgColor={contact.bgColor}
        />
      );
    });
  }

  function renderGrab() {
    const onGrabClicked = () => {
      window.location.href =
        "https://food.grab.com/my/en/restaurant/yum-coffee-kepong-delivery/1-C4KYLBMJT4EKTN";
      return null;
    };
    return (
      <div onClick={onGrabClicked}>
        <ContactCard
          key="grab"
          label="Order Now"
          icon={<div>Grab</div>}
          bgColor="bg-[#00B14F]"
        />
      </div>
    );
  }

  return (
    <div className="py-5 space-y-5">
      <div className="space-y-5">
        {renderGrab()}
        {renderContacts()}
      </div>
    </div>
  );
}
