import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./layouts";
import Error from "./pages/error";
import Home from "./pages/home";
import PrivacyPolicy from "./pages/privacy-policy";
import Grab from "./pages/grab";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {/* Your existing routes */}
          <Route path="/" element={<Home />} />
          <Route path="/grab" element={<Grab />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* Add the catch-all route at the end */}
          <Route path="*" element={<Error />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
