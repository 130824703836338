interface ContactCardProps {
  label: string;
  link?: string;
  icon: React.ReactNode;
  bgColor: string;
}

export default function ContactCard({
  label,
  link,
  icon,
  bgColor,
}: ContactCardProps) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={`
        flex items-center justify-between 
        p-5 rounded-xl ${bgColor} 
        text-white 
        transform transition-all duration-300
        hover:scale-105 hover:shadow-lg hover:brightness-110
        cursor-pointer
        backdrop-blur-sm bg-opacity-90
        border border-white/10
      `}
    >
      <span className="font-semibold text-lg tracking-wide">{label}</span>
      <div className="transform transition-transform duration-300 hover:rotate-12">
        {icon}
      </div>
    </a>
  );
}
