const PrivacyPolicy = () => {
  const formatter = new Intl.DateTimeFormat("en-MY");

  return (
    <div className="min-h-screen bg-gradient-to-b from-amber-50 to-white pt-10 pb-10">
      <div className="max-w-4xl mx-auto p-8 pb-10">
        <h1 className="text-4xl font-bold mb-8 text-center text-amber-900 border-b-2 border-amber-200 pb-4">
          Privacy Policy
        </h1>

        {sections.map((section, index) => (
          <section
            key={index}
            className="mb-12 bg-white rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow"
          >
            <h2 className="text-2xl font-semibold mb-4 text-amber-800 flex items-center">
              <span className="bg-amber-100 w-8 h-8 rounded-full flex items-center justify-center mr-3 text-amber-700">
                {index + 1}
              </span>
              {section.title}
            </h2>
            <div className="text-gray-700 leading-relaxed">
              {section.content}
              {section.list && (
                <ul className="list-none ml-6 mb-4 mt-4">
                  {section.list.map((item, i) => (
                    <li key={i} className="flex items-center mb-2">
                      <span className="mr-2 text-amber-600">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>
        ))}

        <footer className="text-sm text-gray-600 text-center mt-12 border-t border-amber-100 pt-4">
          <p>Last updated: {formatter.format(new Date())}</p>
        </footer>
      </div>
    </div>
  );
};

const sections = [
  {
    title: "Information We Collect",
    content:
      "When you visit Yum Coffee, we may collect certain information about your device, browsing actions, and patterns. This includes:",
    list: [
      "Device information",
      "Location information",
      "Information you provide when placing orders",
    ],
  },
  {
    title: "How We Use Your Information",
    content: "We use the information we collect to:",
    list: [
      "Process your orders and payments",
      "Improve our website and services",
      "Send you marketing communications (with your consent)",
      "Analyze website usage and trends",
    ],
  },
  {
    title: "Information Sharing",
    content:
      "We do not sell or trade your personal information. We may share your information with:",
    list: [
      "Service providers who assist our operations",
      "Law enforcement when required by law",
      "Business partners with your consent",
    ],
  },
  {
    title: "Cookies",
    content:
      "We use cookies to enhance your browsing experience. You can control cookies through your browser settings.",
  },
  {
    title: "Your Rights",
    content: "You have the right to:",
    list: [
      "Access your personal data",
      "Correct inaccurate data",
      "Request deletion of your data",
      "Opt-out of marketing communications",
    ],
  },
  {
    title: "Contact Us",
    content:
      "If you have questions about this Privacy Policy, please contact us at: hello@yumcoffee.com",
  },
];

export default PrivacyPolicy;
